import axios from 'axios'

axios.defaults.baseURL='https://api.biaoshijiexi.com/'

axios.defaults.timeout=20000

axios.interceptors.request.use((config)=>{
    return config
},(err)=>{
    return Promise.reject(err)
})

axios.interceptors.response.use((res)=>{
    return res
},(err)=>{
    return Promise.reject(err)
})

export default axios