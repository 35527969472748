<template>
  <div class="cancellationdetail">
    <div>
      <img class="cancellationImg" src="@/assets/img/detail.png" alt="" />
      <div class="cancellationContent">
        <div class="cancellationText">解约申请已提交</div>
        <div class="cancellationText1">请耐心等待，可随时关注解约进度</div>
      </div>
    </div>
    <div class="liucheng">
      <van-steps direction="vertical" :active="0" active-color="#1270E3 ">
        <van-step>
          <div class="lcContent">
            <div>已提交</div>
            <div>{{date}}</div>
            <img class="duiicon" src="@/assets/img/duiicon.png" alt="" />
          </div>
        </van-step>
        <van-step>
          <div class="lcContent">
            <div>待解约</div>
            <div>{{enddate}}</div>
          </div>
        </van-step>
      </van-steps>
    </div>
    <div style="margin: 16px">
      <van-button
        class="jyBtn"
        @click="contact"
        round
        block
        type="info"
        native-type="submit"
      >
        <img class="kefuicon" src="@/assets/img/kefuicon.png" alt="" />
        联系客服</van-button
      >
    </div>
  </div>
</template>

<script>
import {getDay} from '@/utils/formtime'
import { refundInfo } from "@/apis/https";
export default {
  data() {
    return {
      telephone: "",
      date:"",
      enddate:""
    };
  },
  created() {
    let time1 = new Date(new Date().getTime() + 7 * 60 * 60 * 24 * 1000)
		let date1 = new Date()
    this.date=getDay(date1,'-')
    this.enddate=getDay(time1,'-')
    let telephone = localStorage.getItem("telephone");
    this.telephone = telephone;
    this.getDetail();
  },
  methods: {
    contact() {
      // console.log(1111);
    },
    async getDetail() {
      // console.log(this.telephone);
      let res = await refundInfo({
        telephone: this.telephone,
      });
      // console.log(res);
    },
  },
};
</script>

<style lang="scss" scoped>
.cancellationdetail {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .liucheng {
    margin-top: 20px;
    margin-left: 88px;
    .lcContent {
      display: flex;
      .duiicon {
        width: 17px;
        height: 12px;
      }
    }
  }
  .cancellationImg {
    width: 173px;
    margin-left: 50%;
    transform: translate(-50%);
  }
  .cancellationText1 {
    color: #999999;
  }
  .kefuicon {
    width: 14px;
  }
}
</style>