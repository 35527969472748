import request from './request'
// 获取验证码
export let sendSms=(data)=>request({
    url:'/api/wx/lease/sendSms',
    method:'post',
    data
})
// 验证码校验
export let validatorSms=(data)=>request({
    url:'/api/wx/lease/validatorSms',
    method:'post',
    data
})
// 申请退款
export let refundApply=(data)=>request({
    url:'/api/wx/lease/refundApply',
    method:'post',
    data
})
// 用户退款详情
export let refundInfo=(data)=>request({
    url:'/api/wx/lease/refundInfo',
    method:'post',
    data
})
// 退款列表
export let refundList=()=>request({
    url:'/api/wx/lease/refundList',
    method:'post'
})
// 退款申请审核
export let refundExamine=(data)=>request({
    url:'/api/wx/lease/refundExamine',
    method:'post',
    data
})
// 解约申请详情
export let refundDetail=(data)=>request({
    url:'/api/wx/lease/refundDetail',
    method:'get',
    data
})
