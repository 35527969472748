<template>
  <div class="cancellation">
    <img class="cancellationImg" src="@/assets/img/icon.png" alt="" />
    <div class="cancellationContent">
      <div class="cancellationText">是否确认解约？</div>
      <div>需要7个工作日审核期后完成解约</div>
      <div>您将无法继续使用溯源终端进行溯源</div>
    </div>
    <div style="margin: 16px">
      <van-button
        class="jyBtn"
        @click="show = true"
        round
        block
        type="info"
        native-type="submit"
        >解约</van-button
      >
    </div>
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="blockTitle">解约申请协议</div>
          <div class="text">
            <div class="text1">·如您有欠费，会自动扣款后解约</div>
            <div class="text1">
              ·如您有溯源终端设备服务，解约后服务不可用，溯源设备将会安排回收
            </div>
          </div>
          <div class="blockBtn">
            <div class="sure" @click="sure">确认解约</div>
            <div class="Nsure" @click="show = false">暂不解约</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <div class="footer" @click="callPhone()">
      电话：<span>{{ servePhone }}</span>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { refundApply } from "@/apis/https";
export default {
  data() {
    return {
      show: false,
      telephone: "",
      servePhone: "4008005851",
    };
  },
  methods: {
    click() {
      this.show = true;
      // console.log(111111);
    },
    // 确认解约按钮
    async sure() {
      const res = await refundApply({
        telephone: this.telephone,
      });
      // console.log(res);
      if (res.data.code == -1) {
        Toast(res.data.message);
        this.show = false;
      } else {
        this.$router.push("/cancellationdetail");
      }
    },
    callPhone() {
      window.location.href = "tel:" + this.servePhone;
    },
  },
  mounted() {
    let telephone = localStorage.getItem("telephone");
    this.telephone = telephone;
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 325px;
  height: 229px;
  background: #ffffff;
  border-radius: 6px;
  .blockTitle {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #101010;
    line-height: 31.5px;
    margin: 17px 0;
  }
  .text {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #666666;
    .text1 {
      margin-left: 20px;
      line-height: 27px;
      margin-right: 30px;
    }
  }
  .blockBtn {
    margin-top: 25px;
    border-top: 1px solid #e9e9e9;
    display: flex;
    .sure {
      width: 50%;
      height: 38px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #999999;
      line-height: 31px;
      padding-top: 17px;
      border-right: 1px solid #e9e9e9;
    }
    .Nsure {
      width: 50%;
      height: 17px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #0064ff;
      line-height: 31px;
      margin: 17px 0;
    }
  }
}
.footer {
  font-size: 17px;
  bottom: 13px;
  text-align: center;
  color: #666;
  width: 100%;
  margin-top: 50px;
  font-weight: bold;
  span {
    color: #1270e3;
    text-decoration: underline;
  }
}
</style>