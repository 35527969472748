import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/login/login.vue"
import Cancellation from "@/views/cancellation/cancellation.vue"
import Cancellationdetail from "@/views/cancellationdetail/cancellationdetail.vue"
import Cancellationsuccess from "@/views/cancellationsuccess/cancellationsuccess.vue"
import Cancellationfail from "@/views/cancellationfail/cancellationfail.vue"

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    name:"login",
    component: Login
  },
  {
    path:'/cancellation',
    name:"cancellation",
    component: Cancellation
  },
  {
    path:'/cancellationdetail',
    name:"cancellationdetail",
    component: Cancellationdetail
  }
  ,
  {
    path:'/cancellationsuccess',
    name:"cancellationsuccess",
    component: Cancellationsuccess
  }
  ,
  {
    path:'/cancellationfail',
    name:"cancellationfail",
    component: Cancellationfail
  }
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
