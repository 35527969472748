import * as dayjs from 'dayjs'
export const formtime=time=>{
    const date=new Date(time)
    const y=date.getFullYear()
    const M=date.getMonth()+1
    const d=date.getDate()
    const h=date.getHours()
    const m=date.getMinutes()
    const s=date.getSeconds()
    return `${y}年${M}月${d}日${h}时${m}分${s}秒`
}
export const formtimeByDayjs=time=>{
   return dayjs(time).format('YYYY-MM-DD HH:mm:ss')
}
export const formatTime = (date) => {
	const year = date.getFullYear()
	const month = date.getMonth() + 1
	const day = date.getDate()
	const hour = date.getHours()
	const minute = date.getMinutes()
	const second = date.getSeconds()
	return (
		[year, month].map(formatNumber).join('/')
	)
}

const formatNumber = (n) => {
	const s = n.toString()
	return s[1] ? s : '0' + s
}
// 获取当天时间
export const getDay = (date, type) => {
	const year = date.getFullYear()
	const month = date.getMonth() + 1
	const day = date.getDate()
	return (
		[year, month, day].map(formatNumber).join(type)
	)
}

