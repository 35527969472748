<template>
  <div class="home">
    <img class="home_img" src="@/assets/img/bg.png" alt="" />
    <div class="home_form">
      <van-form :rules="rules" :model="ruleForm" ref="ruleForm">
        <van-field
          v-model="ruleForm.telephone"
          name="手机号码"
          clearable
          placeholder="请输入手机号码"
        />
        <van-field
          v-model="ruleForm.sms"
          center
          clearable
          placeholder="请输入验证码"
        >
          <template #button>
            <van-button
              class="vanBtn"
              size="small"
              round
              plain
              hairline
              :disabled="disabled"
              type="primary"
              @click="getSms"
              >{{ text }}</van-button
            >
          </template>
        </van-field>
        <!-- <div class="yzcode">
          <van-field
            v-model="ruleForm.usercode"
            name="验证码"
            clearable
            placeholder="请输入右边验证码"
          />
          <div class="yzcode1">
            <canvas width="100" height="30" ref="captcha"></canvas>
            <img class="sxicon" src="@/assets/img/sxicon.png" alt="" />
          </div>
        </div> -->
        <div style="margin: 16px">
          <van-button
            class="submitBtn"
            round
            block
            type="info"
            native-type="submit"
            @click="onSubmit"
            >下一步</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { sendSms, validatorSms } from "@/apis/https";
import Captcha from "captcha-mini";
import { checktelephone } from "@/utils/validator";
export default {
  data() {
    return {
      ruleForm: {
        telephone: "",
        sms: "",
        usercode: "",
      },
      rules: {
        telephone: { validator: checktelephone, trigger: "blur" },
      },
      Capcode: "",
      disabled: false,
      text: "获取验证码",
    };
  },
  methods: {
    // 获取验证码
    async getSms() {
      if (!this.ruleForm.telephone) {
        return Toast("请输入正确的手机号");
      }
      if (!/^1[3-9]\d{9}$/.test(this.ruleForm.telephone)) {
        return Toast("请输入正确的手机号码");
      }
      let countdownTime = 60;
      // Toast("验证码已发送");
      let clearsettimeout = setInterval(() => {
        --countdownTime;
        this.disabled = true;
        this.text = `${countdownTime}秒后重新获取`;
        if (countdownTime < 0) {
          clearInterval(clearsettimeout);
          this.disabled = false;
          this.text = "获取验证码";
        }
      }, 1000);
      let res = await sendSms({ telephone: this.ruleForm.telephone });
      if ((res.data.code = 1)) {
        Toast("验证码已发送");
      }
    },
    async onSubmit() {
      if (!this.ruleForm.telephone) {
        return Toast("手机号不能为空");
      }
      if (!/^1[3-9]\d{9}$/.test(this.ruleForm.telephone)) {
        return Toast("请输入正确的手机号码");
      }
      if (!this.ruleForm.sms) {
        return Toast("请输入验证码");
      }
      // if (!this.ruleForm.usercode) {
      //   Toast("请输入图形验证码");
      // }
      // else if (this.ruleForm.usercode != this.Capcode) {
      //   Toast("图形验证码错误");
      // }
      else {
        // console.log(1111111);
        let res = await validatorSms({
          telephone: this.ruleForm.telephone,
          code: this.ruleForm.sms,
        });
        if ((res.data.code = -1)) {
          Toast(res.data.message);
        }
        // console.log("11111111",res);
        if ((res.data.data = 1)) {
          Toast("登录成功");
          localStorage.setItem("telephone", this.ruleForm.telephone);
          this.$router.push("/cancellation");
        }
        if ((res.data.data = 0)) {
          Toast("登录成功");
          localStorage.setItem("telephone", this.ruleForm.telephone);
          this.$router.push("/cancellationdetail");
        }
      }
    },
  },
  mounted() {
    // 初始化验证码
    let Capcode = "";
    // 绘制验证码
    new Captcha().draw(this.$refs.captcha, (r) => {
      Capcode = r.toLowerCase();
    });
    this.Capcode = Capcode;
  },
};
</script>

<style lang="scss" scoped>
.home_img {
  width: 100%;
}

.home {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

.home_form {
  box-sizing: border-box;
  position: absolute;
  top: 171px;
  left: 0;
  padding: 20px;
  width: 100%;
  height: 490px;
  background: #ffffff;
  border-radius: 21px;
}
.vanBtn {
  color: #9ecaff !important;
  border: #9ecaff !important;
}

.submitBtn {
  background: #9ecaff !important;
  border: #9ecaff !important;
}

.yzcode {
  display: flex;
  position: relative;
  box-sizing: border-box;
  .yzcode1 {
    display: flex;
    position: absolute;
    right: 20px;
    margin-top: 6px;
  }
  .sxicon {
    margin-top: 4px;
    width: 20px;
    height: 20px;
  }
}
</style>