<template>
  <div class="cancellationdetail">
    <!-- <div>
      <img class="cancellationImg" src="@/assets/img/detail.png" alt="" />
      <div class="cancellationContent">
        <div class="cancellationText">解约失败</div>
        <div class="cancellationText1">暂无法解约，请稍后再试或联系客户咨询。</div>
      </div>
    </div>
    <div style="margin: 16px">
      <van-button
        class="jyBtn"
        @click="contact"
        round
        block
        type="info"
        native-type="submit"
      >
        <img class="kefuicon" src="@/assets/img/kefuicon.png" alt="" />
        联系客服</van-button
      >
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    contact() {
      console.log(1111);
    },
  },
};
</script>

<style lang="scss" scoped>
// .cancellationdetail {
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   .liucheng {
//     margin-left: 38px;
//     .lcContent {
//       display: flex;
//       .duiicon {
//         width: 17px;
//         height: 12px;
//       }
//     }
//   }
//   .cancellationImg {
//     width: 173px;
//     margin-left: 50%;
//     transform: translate(-50%);
//   }
//   .cancellationText1 {
//     color: #999999;
//   }
//   .kefuicon {
//     width: 14px;
//   }
// }
</style>